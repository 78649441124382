<template src="./app.html"></template>

<script>
    import dmAppMixin from "../dmFramework/dmJS/dmAppMixin";
    import signInPage from './components/signInPage/signInPage'
    import rehabOrdersPage from './components/rehabOrdersPage/rehabOrdersPage'
    import rehabOrdersEditModal from './components/rehabOrdersEditModal/rehabOrdersEditModal'
    import rehabOrdersTimeTreatmentLogEditModal from './components/rehabOrdersTimeTreatmentLogEditModal/rehabOrdersTimeTreatmentLogEditModal'
    import hospitalReportsPage from './components/hospitalReportsPage/hospitalReportsPage'
    import hospitalProfileEditModal from './components/hospitalProfileEditModal/hospitalProfileEditModal'
    import reportsPage from './components/reportsPage/reportsPage'
    import adminReportsPage from './components/adminReportsPage/adminReportsPage'
    import usersPage from './components/usersPage/usersPage'
    import usersEditModal from './components/usersEditModal/usersEditModal'
    import hospitalsPage from './components/hospitalsPage/hospitalsPage'
    import hospitalsEditModal from './components/hospitalsEditModal/hospitalsEditModal'
    import usersMyProfileEditModal from './components/usersMyProfileEditModal/usersMyProfileEditModal'
    import supportModal from './components/supportModal/supportModal'

    export default {
        name: 'app',
        mixins: [
            dmAppMixin
        ],
        components: {
            signInPage,
            rehabOrdersPage,
            rehabOrdersEditModal,
            rehabOrdersTimeTreatmentLogEditModal,
            hospitalReportsPage,
            hospitalProfileEditModal,
            reportsPage,
            adminReportsPage,
            usersPage,
            usersEditModal,
            hospitalsPage,
            hospitalsEditModal,
            usersMyProfileEditModal,
            supportModal
        },
        data: function () {
            return {
                mnuSidebarItems: [],
                mnuSidebarItemsHospital: [
                    {
                        text: 'Rehab Orders',
                        onClick: this.mnuOrdersOnClick,
                        iconCss: 'fa fa-home'
                    },
                    {
                        text: 'Reports',
                        onClick: this.mnuHospitalReportsOnClick,
                        iconCss: 'fa fa-bar-chart'
                    },
                    {
                        text: 'Hospital Profile',
                        onClick: this.mnuHospitalProfileOnClick,
                        iconCss: 'fa fa-hospital-o'
                    },
                ],
                mnuSidebarItemsAdmin: [
                    {
                        text: 'Users',
                        onClick: this.mnuUsersOnClick,
                        iconCss: 'fa fa-users'
                    },
                    {
                        text: 'Hospitals',
                        onClick: this.mnuHospitalsOnClick,
                        iconCss: 'fa fa-hospital-o'
                    },
                    {
                        text: 'Reports',
                        onClick: this.mnuAdminReportsOnClick,
                        iconCss: 'fa fa-bar-chart'
                    },
                ],
                mnuSidebarItemsTherapist: [
                    {
                        text: 'My Rehab Orders',
                        onClick: this.mnuOrdersOnClick,
                        iconCss: 'fa fa-home'
                    },
                ],
                mnuRightItems: [
                    {
                        text: 'User',
                        iconCss: 'fa fa-user',
                        items: [
                            {
                                text: 'My Profile',
                                onClick: this.mnuMyProfileOnClick,
                                iconCss: 'fa fa-user'
                            },
                            {
                                text: 'Sign Out',
                                onClick: this.mnuSignOutOnClick,
                                iconCss: 'fa fa-sign-out'
                            },
                        ]
                    }
                ],
                mnuSupportItems: [
                    {
                        text: 'Support',
                        onClick: this.mnuSupportOnClick,
                        iconCss: 'fa fa-question-circle'
                    },
                ],
                tmpMainTemplateName: '',
                signedInUser: null,
                moduleName: '',
                sidebarCollapsed: false,
            }
        },
        methods: {
            toggleClick: function () {
                this.$refs.sidebarInstance.toggle();
            },
            logoOnClick() {

            },
            mnuOnClick(args) {
                if (args.item.onClick) {
                    args.item.onClick();
                }
            },
            mnuOrdersOnClick() {
                this.loadPage('rehabOrdersPage');
            },
            mnuHospitalReportsOnClick() {
                this.loadPage('reportsPage');
            },
            mnuHospitalProfileOnClick() {
                DM.http({
                    method: "GET",
                    url: "/Facilities/GetByID",
                    params: {
                        FacilityID: this.signedInUser.UserFacilities[0].FacilityID
                    }
                })
                    .then(response => {
                        eventBus.$emit('showAppModal', this, 'hospitalProfileEditModal', {
                            mode: "edit",
                            hospitalProfile: response,
                        });
                    });
            },
            mnuUsersOnClick() {
                this.loadPage('usersPage');
            },
            mnuAdminReportsOnClick() {
                this.loadPage('adminReportsPage');
            },
            mnuMyProfileOnClick() {
                DM.http({
                    method: "GET",
                    url: "/Users/GetByID",
                    params: {
                        UserID: app.signedInUser.UserID
                    }
                })
                    .then(response => {
                        app.signedInUser = response;
                        this.signedInUser = response;

                        eventBus.$emit('showAppModal', this, 'usersMyProfileEditModal', {
                            mode: "edit",
                            user: response,
                        });
                    });
            },
            mnuHospitalsOnClick() {
                this.loadPage('hospitalsPage');
            },
            mnuSignOutOnClick() {
                this.doSignOut();
            },
            mnuSupportOnClick() {
                eventBus.$emit('showAppModal', this, 'supportModal', {});
            },
            doSignOut() {
                DM.http({
                    method: "POST",
                    url: "/Users/SignOut",
                })
                    .then(response => {
                        DM.localStorageRemove(app.localStorageAuthTokenName);
                        app.signedInUser = null;
                        this.signedInUser = null;
                        this.loadPage('signInPage');
                    });
            },
            getUserByID(userID) {
                DM.http({
                    method: "GET",
                    url: "/Users/GetByID",
                    params: {
                        UserID: userID
                    }
                })
                    .then(response => {
                        app.signedInUser = response;
                        this.signedInUser = response;
                        this.mnuRightItems[0].text = response.FirstName + ' ' + response.LastName;
                        setTimeout(() => {
                            if (response.UserType === 'Admin') {
                                this.mnuSidebarItems = this.mnuSidebarItemsAdmin;
                                this.moduleName = 'Hospitals';
                                this.loadPage('usersPage');
                            }

                            if (response.UserType === 'Hospital') {
                                this.mnuSidebarItems = this.mnuSidebarItemsHospital;
                                this.moduleName = 'Hospitals';
                                this.loadPage('rehabOrdersPage');
                            }

                            if (response.UserType === 'Therapist') {
                                this.mnuSidebarItems = this.mnuSidebarItemsTherapist;
                                this.moduleName = 'Hospitals';
                                this.loadPage('rehabOrdersPage');
                            }
                        }, 300);
                    })
            },
            signInWithAuthToken() {
                return DM.http({
                    method: "POST",
                    url: "/Users/SignInWithAuthToken",
                })
                    .then(response => {
                        this.getUserByID(response.UserID);
                    })
                    .catch(() => {
                        DM.localStorageRemove(app.localStorageAuthTokenName);
                    });
            },
        },
        created() {

        },
        mounted: function () {
            eventBus.$on('setSignedInUser', (userData) => {
                this.getUserByID(userData.UserID);
            });

            eventBus.$on('resetUserToSignIn', (userData) => {
                // This is called when a 401 is returned
            });

            let authToken = DM.localStorageGet(app.localStorageAuthTokenName);
            if (authToken) {
                DM.authToken = authToken;
                this.signInWithAuthToken();
            } else {
                this.loadPage('signInPage');
            }
        }
    }
</script>