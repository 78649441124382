<template src="./hospitalsEditModal.html"></template>

<script>
    import dmModalMixin from '../../../dmFramework/dmJS/dmModalMixin'
    import states from '../../../dmFramework/dmJS/dmStates'

    export default {
        name: 'users-edit-modal',
        mixins: [dmModalMixin],
        props: [
            "modalData",
        ],
        data: function () {
            return {
                modalName: 'usersEditModal' + DM.getRandomGuid(),
                lblTitle: 'Add Hospital',
                hospitalName: '',
                address1: '',
                address2: '',
                city: '',
                state: '',
                stateOptions: states,
                zipCode: '',
                phone: '',
                fax: '',
                website: '',
                serviceTypes: '',
                serviceTypeOptions: [],
                notes: '',
                mode: '',
            }
        },
        methods: {
            saveHospital() {
                let url = '/Facilities/Create';
                let data = DM.copy(this.modalData.hospital);

                if (this.modalData.mode === 'edit') {
                    data.FacilityID = this.modalData.hospital.FacilityID;
                    url = '/Facilities/Update';
                }

                let serviceTypes = [];
                if (this.serviceTypes) {
                    serviceTypes = this.serviceTypes.map(h => { return { ServiceTypeID: this.serviceTypeOptions.filter(o => o.text === h)[0].value } });
                }

                delete data.FacilityCreatedUtc;
                delete data.FacilityUpdatedUtc;
                delete data.FacilityIsDeleted;

                Object.assign(data, {
                    FacilityName: this.hospitalName,
                    MailingAddress1: this.address1,
                    MailingAddress2: this.address2,
                    MailingCity: this.city,
                    MailingState: this.state,
                    MailingZipCode: this.zipCode,
                    MainPhone: this.phone,
                    MainFax: this.fax,
                    FacilityWebsite: this.website,
                    ServiceTypes: serviceTypes,
                    FacilityNotes: this.notes,
                    FacilityType: 'Hospital'
                });

                return DM.http({
                    method: "POST",
                    url: url,
                    data: data
                });
            },
            btnSaveOnClick() {
                this.saveHospital()
                    .then(response => {
                        this.modalData.callback();
                        this.modalRef.hide();
                    });
            },
            btnSaveAndAddMoreOnClick() {
                this.saveHospital()
                    .then(response => {
                        this.modalData.callback();
                        this.hospitalName = '';
                        this.address1 = '';
                        this.address2 = '';
                        this.city = '';
                        this.state = null;
                        this.zipCode = '';
                        this.phone = '';
                        this.fax = '';
                        this.website = '';
                        this.notes = '';
                        this.serviceTypes = [];
                    });
            },
            btnCancelOnClick() {
                this.modalRef.hide();
            },
            getServiceTypeOptions() {
                return DM.http({
                    method: "GET",
                    url: "/ServiceTypes/GetAll",
                }).then(response => {
                    response.forEach(type => {
                        if(!this.serviceTypeOptions.filter(o => o.value === type.ServiceTypeID)[0]) {
                            let dataObject = {text: type.ServiceTypeName, value: type.ServiceTypeID};
                            this.serviceTypeOptions.push(dataObject);
                        }
                    });

                    if(this.mode === 'edit') {
                        this.serviceTypes = this.modalData.hospital.ServiceTypes.map(s => {
                            return this.serviceTypeOptions.filter(o => o.value === s.ServiceTypeID )[0].text
                        });
                    }
                });
            },
        },
        created() {
            this.getServiceTypeOptions(this.modalData.hospital.FacilityID);

            let hospital = this.modalData.hospital;

            this.mode = this.modalData.mode;

            if (this.modalData.mode === 'edit') {
                this.lblTitle = 'Edit Hospital: ' + hospital.FacilityName;
                this.hospitalName = hospital.FacilityName;
                this.address1 = hospital.MailingAddress1;
                this.address2 = hospital.MailingAddress2;
                this.city = hospital.MailingCity;
                this.state = hospital.MailingState;
                this.zipCode = hospital.MailingZipCode;
                this.phone = hospital.MainPhone;
                this.fax = hospital.MainFax;
                this.website = hospital.FacilityWebsite;
                this.notes = hospital.FacilityNotes;
            }
        },
        mounted() {

        }
    }
</script>
