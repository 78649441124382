<template src="./rehabOrdersTimeTreatmentLogEditModal.html"></template>

<script>
import dmModalMixin from '../../../dmFramework/dmJS/dmModalMixin'
import { Edit, Page, Toolbar } from "@syncfusion/ej2-vue-grids";

export default {
    name: 'rehab-orders-time-treatment-log-edit-modal',
    mixins: [dmModalMixin],
    props: [
        "modalData",
    ],
    provide: {
        grid: [Edit, Page, Toolbar]
    },
    data: function () {
        return {
            modalName: 'rehabOrdersTimeTreatmentLogEditModal' + DM.getRandomGuid(),
            lblTitle: 'Time/Treatment Log for: Rehab Order ',
            patientFirstName: '',
            patientLastName: '',
            patientDob: '',
            serviceType: '',
            hospitalName: '',
            notes: '',
            editSettings: {
                allowEditing: true,
                allowAdding: true,
                allowDeleting: true,
            },
            toolbar: ['Add', 'Edit', 'Delete', 'Update', 'Cancel'],
            grdTimeLogsFormatOptions: { type: 'dateTime', format: 'M/d/y hh:mm a' },
            grdTreatmentLogsFormatOptions: { type: 'dateTime', format: 'M/d/y' },
            validationRules: { number: true },
            timeLogs: null,
            treatmentLogs: null,
            minuteFormatter: { format: "####.## 'mins'" },
            grdTimeLogsSortBy: {
                columns: [
                    {field: 'TimeStartUtc', direction: 'Descending'},
                ]
            },
            grdTreatmentLogsSortBy: {
                columns: [
                    {field: 'TreatmentDate', direction: 'Descending'},
                ]
            },
        }
    },
    methods: {
        saveTimeLogs() {
            let promises = [];

            let logs = DM.copy(this.$refs.timeGrid.getDataModule().dataManager.dataSource.json);

            logs.forEach(t => {
                let url = '/TimeLogs/Update';

                if(typeof t.TimeLogID === 'string' && t.TimeLogID.length === 36) { // This checks if the TimeLogID is a temporary GUID ID
                    url = '/TimeLogs/Create';
                }

                let data = {
                    TimeLogID: typeof t.TimeLogID === 'string' && t.TimeLogID.length === 36 ? undefined : t.TimeLogID,
                    TimeStartUtc: t.TimeStartUtc,
                    TimeEndUtc: t.TimeEndUtc,
                    RehabOrderID: this.modalData.rehabOrder.RehabOrderID,
                    TimeLogRowVersion: t.TimeLogRowVersion,
                };

                promises.push(
                    DM.http({
                        method: "POST",
                        url: url,
                        data: data
                    })
                        .then(response => {
                            let timeLog = this.$refs.timeGrid.getDataModule().dataManager.dataSource.json.filter(l => l.TimeLogID === t.TimeLogID)[0];
                            timeLog.TimeLogRowVersion = response.TimeLogRowVersion;
                            if(url.includes('Create')) {
                                timeLog.TimeLogID = response.TimeLogID;
                            }
                        })
                );
            });

            this.savedInitialData.timeLogs.forEach(t => {
                let log = logs.filter(l => l.TimeLogID === t.TimeLogID)[0];
                if(!log) {
                    promises.push(
                        DM.http({
                            method: "POST",
                            url: '/TimeLogs/Delete',
                            data: {
                                TimeLogID: t.TimeLogID,
                                TimeLogRowVersion: t.TimeLogRowVersion
                            }
                        })
                            .then(response => {
                                this.savedInitialData.timeLogs = this.savedInitialData.timeLogs.filter(l => l.TimeLogID !== t.TimeLogID);
                            })
                    );
                }
            });

            return Promise.all(promises);
        },
        saveTreatmentLogs() {
            let promises = [];

            let logs = DM.copy(this.$refs.treatmentGrid.getDataModule().dataManager.dataSource.json);

            logs.forEach(t => {
                let url = '/TreatmentLogs/Update';

                if(typeof t.TreatmentLogID === 'string' && t.TreatmentLogID.length === 36) { // This checks if the TreatmentLogID is a temporary GUID ID
                    url = '/TreatmentLogs/Create';
                }

                let data = {
                    TreatmentLogID: typeof t.TreatmentLogID === 'string' && t.TreatmentLogID.length === 36 ? undefined : t.TreatmentLogID,
                    TreatmentDate: t.TreatmentDate,
                    TreatmentMinutes: t.TreatmentMinutes,
                    TreatmentLogNotes: t.TreatmentLogNotes || '',
                    RehabOrderID: this.modalData.rehabOrder.RehabOrderID,
                    TreatmentLogRowVersion: t.TreatmentLogRowVersion,
                };

                promises.push(
                    DM.http({
                        method: "POST",
                        url: url,
                        data: data
                    })
                        .then(response => {
                            let treatmentLog = this.$refs.treatmentGrid.getDataModule().dataManager.dataSource.json.filter(l => l.TreatmentLogID === t.TreatmentLogID)[0];
                            treatmentLog.TreatmentLogRowVersion = response.TreatmentLogRowVersion;
                            if(url.includes('Create')) {
                                treatmentLog.TreatmentLogID = response.TreatmentLogID;
                            }
                        })
                );
            });

            this.savedInitialData.treatmentLogs.forEach(t => {
                let log = logs.filter(l => l.TreatmentLogID === t.TreatmentLogID)[0];
                if(!log) {
                    promises.push(
                        DM.http({
                            method: "POST",
                            url: '/TreatmentLogs/Delete',
                            data: {
                                TreatmentLogID: t.TreatmentLogID,
                                TreatmentLogRowVersion: t.TreatmentLogRowVersion
                            }
                        })
                            .then(response => {
                                this.savedInitialData.treatmentLogs = this.savedInitialData.treatmentLogs.filter(l => l.TreatmentLogID !== t.TreatmentLogID);
                            })
                    );
                }
            });

            return Promise.all(promises);
        },
        btnSaveOnClick() {
            this.saveTimeLogs()
                .then(response => {
                    return this.saveTreatmentLogs();
                })
                .then(response => {
                    this.modalRef.hide();
                });
        },
        btnCancelOnClick() {
            this.modalRef.hide();
        },
        actionBeginTimeLog(args) {
            if (args.requestType === 'save' || args.requestType === 'delete') {
                if (args.action === 'add') {
                    args.data.TimeLogID = DM.getRandomGuid();
                }
            }
        },
        actionBeginTreatmentLog(args) {
            if (args.requestType === 'save' || args.requestType === 'delete') {
                if (args.action === 'add') {
                    args.data.TreatmentLogID = DM.getRandomGuid();
                }
            }
        },
    },
    created() {
        this.lblTitle += this.modalData.rehabOrder.RehabOrderID;
        this.patientFirstName = this.modalData.rehabOrder.PatientFirstName;
        this.patientLastName = this.modalData.rehabOrder.PatientLastName;
        this.patientDob = DM.formatDateDisplay(this.modalData.rehabOrder.PatientBirthDate);
        this.serviceType = this.modalData.rehabOrder.serviceType;
        this.hospitalName = this.modalData.rehabOrder.hospitalName;
        this.notes = this.modalData.rehabOrder.RehabOrderNotes;
        this.timeLogs = this.modalData.timeLogs;
        this.treatmentLogs = this.modalData.treatmentLogs;
    },
    mounted() {

    }
}
</script>
