import Vue from 'vue'
import App from './App.vue';

import dmAlertModal from '../dmFramework/dmJS/dmAlertModal';
import dmConfirmModal from '../dmFramework/dmJS/dmConfirmModal';
import dmTable from '../dmFramework/dmJS/dmTable.vue';
import DM from '../dmFramework/dmJS/dmUtils';
import app from './js/app.js';
import axios from 'axios'
import moment from 'moment'

import {L10n} from '@syncfusion/ej2-base';
import {ButtonPlugin, CheckBoxPlugin} from '@syncfusion/ej2-vue-buttons';
import {MenuPlugin, SidebarPlugin} from '@syncfusion/ej2-vue-navigations';
import {GridPlugin, Sort} from '@syncfusion/ej2-vue-grids';
import {DialogPlugin} from '@syncfusion/ej2-vue-popups';
import {TextBoxPlugin} from '@syncfusion/ej2-vue-inputs';
import {DatePickerPlugin, DateRangePickerPlugin} from '@syncfusion/ej2-vue-calendars';
import {DropDownButtonPlugin} from "@syncfusion/ej2-vue-splitbuttons";
import {DropDownListPlugin, MultiSelectPlugin} from "@syncfusion/ej2-vue-dropdowns";

import './css/app.css'
import './css/syncFusion.css'
import './css/syncfusion-material.all.css'
import '../dmFramework/dmCSS/dmGeneral.css'
import '../dmFramework/dmCSS/dmSizing.css'
import '../dmFramework/dmCSS/dmDisplay.css'
import '../dmFramework/dmCSS/dmLayout.css'
import '../dmFramework/dmCSS/dmColors.css'
import '../dmFramework/dmCSS/dmIcons.css'
import '../dmFramework/dmIcons/faIcons/faCSS/font-awesome.min.css'
import "@syncfusion/ej2-icons/styles/material.css";
import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-vue-buttons/styles/material.css";
import "@syncfusion/ej2-vue-navigations/styles/material.css";
import "@syncfusion/ej2-vue-grids/styles/material.css";
import "@syncfusion/ej2-vue-popups/styles/material.css";
import "@syncfusion/ej2-vue-inputs/styles/material.css";
import "@syncfusion/ej2-vue-calendars/styles/material.css";
import '@syncfusion/ej2-splitbuttons/styles/material.css';
import '@syncfusion/ej2-vue-dropdowns/styles/material.css';

Vue.component('dmAlertModal', dmAlertModal);
Vue.component('dmConfirmModal', dmConfirmModal);
Vue.component('dmTable', dmTable);

window.DM = DM.utils;
window.app = app;
window.axios = axios;
window.moment = moment;

L10n.load({
    'en-US': {
        'grid': {
            'EmptyRecord': 'No records to display.',
        },
    }
});

Vue.use(ButtonPlugin);
Vue.use(CheckBoxPlugin);
Vue.use(MenuPlugin);
Vue.use(SidebarPlugin);
Vue.use(GridPlugin);
Vue.use(DialogPlugin);
Vue.use(TextBoxPlugin);
Vue.use(DatePickerPlugin);
Vue.use(DateRangePickerPlugin);
Vue.use(DropDownButtonPlugin);
Vue.use(DropDownListPlugin);
Vue.use(MultiSelectPlugin);

Vue.config.errorHandler = (err, vm, info) => {
    // console.log(clickTracking);
    // console.log(err);

    if(window.environmentName === 'dev') {
        return;
    }

    DMF.sendErrorToDelMarMessaging({
        projectKey: '8af3d529951d4177a6344fb1aef86a61',
        clicks: clickTracking,
        stackTrace: err
    });
}

let clickTracking = [];
window.addEventListener('click', ($event) => {
    clickTracking.push($event.target.outerHTML);
    if (clickTracking.length > 10) {
        clickTracking.shift();
    }
});

window.eventBus = new Vue();

new Vue({
    el: '#app',
    render: h => h(App)
});
