export default {
    data() {
        return {
            ref: null,
            sfTarget: document.body,
            modalRef: null
        }
    },
    methods: {

    },
    watch: {
        modalRef: function() {
            this.modalRef.show();

            this.modalRef.$on("close", () => {
                eventBus.$emit('closeAppModal', {
                    modalComponent: this.modalRef.$attrs.id
                });
            });
        },
    },
    created() {

    },
    mounted() {
        try {
            this.savedInitialData = {};
            for(let property in this.$data) {
                if(!(this.$data[property] && typeof this.$data[property] === 'object' && this.$data[property].hasOwnProperty('innerHTML'))) {
                    this.savedInitialData[property] = this.$data[property];
                }
            }
        } catch(error) {
            console.log(error);
        }

        this.modalRef = this.$refs[this.modalName];
    }
}