<template src="./usersPage.html"></template>

<script>
    import Vue from 'vue'

    export default {
        name: 'users-page',
        data: function () {
            return {
                grdUsersFields: [],
                grdUsersItems: [],
                grdUsersSortBy: {
                    columns: [
                        {field: 'LastName', direction: 'Ascending'},
                    ]
                },
                grdUsersClass: 'dm-bg-color-black',
                userStatus: '',
                userStatusOptions: [],
                userType: '',
                userTypeOptions: [],
                searchText: '',
                grdUsersActions: [
                    {
                        text: 'Edit'
                    },
                    {
                        text: 'Delete'
                    }
                ],
                selectedRow: null
            }
        },
        methods: {
            getUsers() {
                DM.http({
                    method: 'GET',
                    url: '/Users/GetAll',
                }).then(response => {
                    this.users = response;
                    this.grdUsersItems = response;
                });
            },
            getUserTypeOptions() {
                this.userTypeOptions = [
                    {
                        text: 'Admin',
                        value: 'Admin'
                    },
                    {
                        text: 'Hospital',
                        value: 'Hospital'
                    },
                    {
                        text: 'Therapist',
                        value: 'Therapist'
                    }
                ];
            },
            grdUsersRowOnClick(args) {
                this.selectedRow = args;
            },
            addUserOnClick() {
                eventBus.$emit('showAppModal', this, 'usersEditModal', {
                    mode: "new",
                    user: {},
                    userTypeOptions: this.userTypeOptions,
                    callback: this.getUsers
                });
            },
            grdUsersActionOnClick(args) {
                if(args.item.text === 'Edit') {
                    eventBus.$emit('showAppModal', this, 'usersEditModal', {
                        mode: "edit",
                        user: this.selectedRow.rowData,
                        userTypeOptions: this.userTypeOptions,
                        callback: this.getUsers
                    });
                }

                if(args.item.text === 'Delete') {
                    DM.confirmShow('Are you sure you want to delete this user?', '', () => {
                        this.deleteUser(this.selectedRow.rowData.UserID, this.selectedRow.rowData.UserRowVersion);
                    }, null, null, 'No');
                }
            },
            deleteUser(userId, rowVersion) {
                DM.http({
                    method: 'POST',
                    url: '/Users/Delete',
                    data: {
                        UserID: userId,
                        UserRowVersion: rowVersion
                    }
                }).then(response => {
                    this.getUsers();
                });
            },
            searchUsersOnClick() {
                let filteredUsers = this.users;

                if(this.userType) {
                    filteredUsers = filteredUsers.filter(u => u.UserType === this.userType);
                }

                if(this.searchText) {
                    filteredUsers = filteredUsers.filter(
                        u => u.FirstName.includes(this.searchText) || u.LastName.includes(this.searchText)
                    );
                }

                this.grdUsersItems = filteredUsers;
            }
        },
        created() {
            this.getUserTypeOptions()
            this.getUsers();

            let self = this;

            this.grdUsersFields = [
                {
                    type: 'template',
                    width: '80px',
                    template: () => {
                        return {
                            template: Vue.component('columnTemplate', {
                                template: `<ejs-dropdownbutton
                                                :items="grdUsersActions"
                                                :select="select"
                                                iconCss="e-icons e-ellipsis-vert"
                                                cssClass= "e-caret-hide">
                                           </ejs-dropdownbutton>`,
                                data: function() {
                                    return {
                                        grdUsersActions: self.grdUsersActions
                                    }
                                },
                                methods: {
                                    select(args) {
                                        self.grdUsersActionOnClick(args);
                                    }
                                }
                            })
                        }
                    }
                },
                {
                    key: "LastName",
                    label: "Last Name"
                },
                {
                    key: "FirstName",
                    label: "First Name"
                },
                {
                    key: "UserType",
                    label: "Type"
                },
                {
                    key: "UserCreatedUtc",
                    label: "Date Created",
                    type: 'date'
                },
                {
                    key: "UserAdminNotes",
                    label: "Notes",
                    width: '300px'
                },
            ];
        },
        mounted() {

        }
    }
</script>
