<template src="./rehabOrdersEditModal.html"></template>

<script>
import dmModalMixin from '../../../dmFramework/dmJS/dmModalMixin'

export default {
    name: 'rehab-orders-edit-modal',
    mixins: [dmModalMixin],
    props: [
        "modalData",
    ],
    data: function () {
        return {
            modalName: 'rehabOrdersEditModal' + DM.getRandomGuid(),
            lblTitle: 'Add Order',
            patientFirstName: '',
            patientLastName: '',
            patientDob: null,
            serviceType: '',
            serviceTypeOptions: null,
            notes: '',
            mode: '',
        }
    },
    methods: {
        saveRehabOrder() {
            let url = '/RehabOrders/Create';
            let data = DM.copy(this.modalData.rehabOrder);

            if(this.modalData.mode === 'edit') {
                data.RehabOrderID = this.modalData.rehabOrder.RehabOrderID;
                url = '/RehabOrders/Update';
            }

            let serviceTypeID = -1;
            if(this.serviceType) {
                serviceTypeID = this.serviceTypeOptions.filter(s => s.text === this.serviceType)[0].value;
            }

            delete data.RehabOrderCreatedUtc;
            delete data.RehabOrderUpdatedUtc;
            delete data.RehabOrderIsDeleted;
            delete data.serviceType;

            Object.assign(data, {
                FacilityID: app.signedInUser.UserFacilities[0].FacilityID,
                PatientFirstName: this.patientFirstName,
                PatientLastName: this.patientLastName,
                ServiceTypeID: serviceTypeID,
                PatientBirthDate: this.patientDob || null,
                OrderStatus: this.modalData.rehabOrder.OrderStatus ? this.modalData.rehabOrder.OrderStatus : 'Pending',
                RehabOrderNotes: this.notes,
            });

            return DM.http({
                method: "POST",
                url: url,
                data: data
            });
        },
        btnSaveOnClick() {
            this.saveRehabOrder()
                .then(response => {
                    this.modalData.callback();
                    this.modalRef.hide();
                });
        },
        btnSaveAndAddMoreOnClick() {
            this.saveRehabOrder()
                .then(response => {
                    this.modalData.callback();
                    this.patientFirstName = '';
                    this.patientLastName = '';
                    this.patientDob = '';
                    this.serviceType = null;
                    this.notes = '';
                });
        },
        btnCancelOnClick() {
            this.modalRef.hide();
        }
    },
    created() {
        let rehabOrder = this.modalData.rehabOrder;

        this.mode = this.modalData.mode;

        if(this.modalData.mode === 'edit' || this.modalData.mode === 'view') {
            if(this.modalData.mode === 'edit') {
                this.lblTitle = 'Edit Order: ' + this.modalData.rehabOrder.RehabOrderID;
            } else {
                this.lblTitle = 'View Order: ' + this.modalData.rehabOrder.RehabOrderID;
            }

            this.patientFirstName = rehabOrder.PatientFirstName;
            this.patientLastName = rehabOrder.PatientLastName;
            this.patientDob = rehabOrder.PatientBirthDate;
            this.serviceType = rehabOrder.serviceType;
            this.notes = rehabOrder.RehabOrderNotes;
        }

        this.serviceTypeOptions = this.modalData.serviceTypeOptions;
    },
    mounted() {

    }
}
</script>
