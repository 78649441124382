<template src="./hospitalsPage.html"></template>

<script>
    import Vue from 'vue'

    export default {
        name: 'hospitals-page',
        data: function () {
            return {
                grdHospitalsFields: [],
                grdHospitalsItems: [],
                grdHospitalsSortBy: {
                    columns: [
                        {field: 'FacilityName', direction: 'Ascending'},
                    ]
                },
                grdHospitalsClass: 'dm-bg-color-black',
                hospitalStatus: '',
                hospitalStatusOptions: [],
                hospitalName: '',
                hospitalNameOptions: [],
                searchText: '',
                grdHospitalsActions: [
                    {
                        text: 'Edit/View'
                    },
                    {
                        text: 'Make Active/Inactive'
                    }
                ],
                selectedRow: null
            }
        },
        methods: {
            getHospitals() {
                DM.http({
                    method: 'GET',
                    url: '/Facilities/GetAll',
                }).then(response => {
                    response.forEach(f => {
                        f.Status = f.FacilityIsDeleted ? 'Inactive' : 'Active';
                    });

                    this.hospitals = response;
                    this.grdHospitalsItems = response;
                    this.hospitalNameOptions = this.grdHospitalsItems.map(h => { return {text: h.FacilityName, value: h.FacilityID} });
                });
            },
            getHospitalStatusOptions() {
                this.hospitalStatusOptions = [
                    {
                        text: 'Active',
                        value: 'Active'
                    },
                    {
                        text: 'Inactive',
                        value: 'Inactive'
                    },
                ];
            },
            grdHospitalsRowOnClick(args) {
                this.selectedRow = args;
            },
            addHospitalOnClick() {
                eventBus.$emit('showAppModal', this, 'hospitalsEditModal', {
                    mode: "new",
                    hospital: {},
                    callback: this.getHospitals
                });
            },
            grdHospitalsActionOnClick(args) {
                if(args.item.text === 'Edit/View') {
                    DM.http({
                        method: 'GET',
                        url: '/Facilities/GetByID',
                        params: {
                            FacilityID: this.selectedRow.rowData.FacilityID,
                        }
                    })
                    .then(response => {
                        eventBus.$emit('showAppModal', this, 'hospitalsEditModal', {
                            mode: "edit",
                            hospital: response,
                            callback: this.getHospitals
                        });
                    });
                }

                if(args.item.text === 'Make Active/Inactive') {
                    DM.confirmShow(
                        'Are you sure you want to make this hospital ' + (this.selectedRow.rowData.FacilityIsDeleted ? 'active' : 'inactive') + '?',
                        '',
                        () => {
                            let url = '/Facilities/Delete';
                            if(this.selectedRow.rowData.FacilityIsDeleted) {
                                url = '/Facilities/Restore';
                            }

                            DM.http({
                                method: 'POST',
                                url: url,
                                data: {
                                    FacilityID: this.selectedRow.rowData.FacilityID,
                                    FacilityRowVersion: this.selectedRow.rowData.FacilityRowVersion
                                }
                            })
                            .then(response => {
                                this.getHospitals();
                            });
                        },
                        null,
                        null,
                        'No'
                    );
                }
            },
            searchHospitalsOnClick() {
                let filteredHospitals = this.hospitals;

                if(this.hospitalName) {
                    filteredHospitals = filteredHospitals.filter(h => h.FacilityName === this.hospitalName);
                }

                if(this.hospitalStatus) {
                    filteredHospitals = filteredHospitals.filter(h => h.Status === this.hospitalStatus);
                }

                if(this.searchText) {
                    filteredHospitals = filteredHospitals.filter(
                        u => u.MailingCity.includes(this.searchText) || u.FacilityNotes.includes(this.searchText)
                    );
                }

                this.grdHospitalsItems = filteredHospitals;
            }
        },
        created() {
            this.getHospitalStatusOptions()
            this.getHospitals();

            let self = this;

            this.grdHospitalsFields = [
                {
                    type: 'template',
                    width: '80px',
                    template: () => {
                        return {
                            template: Vue.component('columnTemplate', {
                                template: `<ejs-dropdownbutton
                                                :items="grdHospitalsActions"
                                                :select="select"
                                                iconCss="e-icons e-ellipsis-vert"
                                                cssClass= "e-caret-hide">
                                           </ejs-dropdownbutton>`,
                                data: function() {
                                    return {
                                        grdHospitalsActions: self.grdHospitalsActions
                                    }
                                },
                                methods: {
                                    select(args) {
                                        self.grdHospitalsActionOnClick(args);
                                    }
                                }
                            })
                        }
                    }
                },
                {
                    key: "FacilityName",
                    label: "Hospital Name"
                },
                {
                    key: "MailingCity",
                    label: "City"
                },
                {
                    key: "MailingState",
                    label: "State"
                },
                {
                    key: "Status",
                    label: "Status"
                },
                {
                    key: "FacilityCreatedUtc",
                    label: "Date Created",
                    type: 'date'
                },
                {
                    key: "FacilityNotes",
                    label: "Notes",
                    width: '300px'
                },
            ];
        },
        mounted() {

        }
    }
</script>