<template src="./hospitalProfileEditModal.html"></template>

<script>
import dmModalMixin from '../../../dmFramework/dmJS/dmModalMixin'
import states from '../../../dmFramework/dmJS/dmStates'

export default {
    name: 'hospital-profile-edit-modal',
    mixins: [dmModalMixin],
    props: [
        "modalData",
    ],
    data: function () {
        return {
            modalName: 'hospitalProfileEditModal' + DM.getRandomGuid(),
            lblTitle: 'Hospital Profile',
            hospitalName: '',
            address1: '',
            address2: '',
            city: '',
            state: '',
            stateOptions: states,
            zipCode: '',
            phone: '',
            fax: '',
            website: '',
            serviceTypes: '',
            serviceTypeOptions: [],
        }
    },
    methods: {
        saveFacility() {
            let data = DM.copy(this.modalData.hospitalProfile);

            delete data.FacilityName;
            delete data.FacilityType;
            delete data.FacilityNotes;
            delete data.ServiceTypes;
            delete data.FacilityCreatedUtc;
            delete data.FacilityUpdatedUtc;
            delete data.FacilityIsDeleted;

            Object.assign(data, {
                FacilityID: this.modalData.hospitalProfile.FacilityID,
                MailingAddress1: this.address1,
                MailingAddress2: this.address2,
                MailingCity: this.city,
                MailingState: this.state,
                MailingZipCode: this.zipCode,
                MainPhone: this.phone,
                MainFax: this.fax,
                FacilityWebsite: this.website,
            });

            return DM.http({
                method: "POST",
                url: '/Facilities/UpdateByHospitalUser',
                data: data
            });
        },
        btnSaveOnClick() {
            this.saveFacility()
                .then(response => {
                    this.modalRef.hide();
                });
        },
        btnCancelOnClick() {
            this.modalRef.hide();
        },
        getServiceTypeOptions() {
            return DM.http({
                method: "GET",
                url: "/ServiceTypes/GetByFacilityID",
                params: {
                    FacilityID: app.signedInUser.UserFacilities[0].FacilityID
                }
            }).then(response => {
                response.forEach(type => {
                    let dataObject = {text: type.ServiceTypeName, value: type.ServiceTypeID};
                    this.serviceTypeOptions.push(dataObject);
                });

                this.serviceTypes = this.modalData.hospitalProfile.ServiceTypes.map(s => {
                    return this.serviceTypeOptions.filter(o => o.value === s.ServiceTypeID )[0].text
                });

                this.serviceTypes = this.serviceTypes.join(', ');
            });
        },
    },
    created() {
        this.getServiceTypeOptions();

        let hospitalProfile = this.modalData.hospitalProfile;

        this.mode = this.modalData.mode;

        this.hospitalName = hospitalProfile.FacilityName;
        this.address1 = hospitalProfile.MailingAddress1;
        this.address2 = hospitalProfile.MailingAddress2;
        this.city = hospitalProfile.MailingCity;
        this.state = hospitalProfile.MailingState;
        this.zipCode = hospitalProfile.MailingZipCode;
        this.phone = hospitalProfile.MainPhone;
        this.fax = hospitalProfile.MainFax;
        this.website = hospitalProfile.FacilityWebsite;
    },
    mounted() {

    }
}
</script>
